<template>
  <div class="action__inner">
    <a-form :model="dataSource" :rules="rules" @finish="sendTransfer" name="transfer" ref="transfer">
      <a-form-item
        ref="writeOffAccount"
        name="writeOffAccount"
        class="input__wrapper"
        :label="i18n.t('start.writeOffAccount')"
      >
        <a-input
          :value="
            i18n.t('start.inputAccount', {
              name: i18n.t('start.name' + transferData.attributes.kind),
              amount: transferData.attributes.amount,
            })
          "
          size="large"
          type="text"
          :disabled="true"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item
        ref="writeInAccount"
        name="writeInAccount"
        class="input__wrapper"
        :label="i18n.t('start.writeInAccount')"
      >
        <a-input
          :value="
            i18n.t('start.inputAccount', {
              name: i18n.t('start.name' + mainAccount.attributes.kind),
              amount: mainAccount.attributes.amount,
            })
          "
          size="large"
          type="text"
          :disabled="true"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item ref="amount" name="amount" class="input__wrapper" :label="i18n.t('start.summ')">
        <a-input
          v-model:value="dataSource.amount"
          size="large"
          style="width: 100%"
          type="number"
          :required="true"
          step="0.01"
        >
          <template #addonAfter>
            <p class="action__title">USDT</p>
          </template>
        </a-input>
      </a-form-item>
      <sdButton
        class="action__button"
        size="large"
        :disabled="isLoading || errorAmount"
        type="primary"
        htmlType="submit"
        raised
      >
        {{ i18n.t('start.transfer') }}
      </sdButton>
      <!--<p class="action__more-info" @click="changePayInfo">
              <sdFeatherIcons type="info" size="20" /> {{ i18n.t('start.moreInfo') }}
            </p>-->
    </a-form>
  </div>
  <!--<div class="action__inner" v-if="visiblePayHint">
          <p class="action__info">
            {{ i18n.t('start.actionTransferHintOne', accId == tokenAccount.id ? 1 : 2) }}
          </p>
          <p class="action__info" v-if="accId == tokenAccount.id">
            {{ i18n.t('start.actionTransferHintTwo') }}
          </p>
        </div>-->
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const TransferModal = {
  name: 'TransferModal',
  props: { error: Boolean },
  emits: ['dataNotSended', 'dataSended'],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { state, dispatch } = useStore();
    const route = useRoute();

    const dataSource = ref({
      amount: '',
    });
    const errorAmount = ref(false);

    const isLoading = computed(() => state.accounts.fileLoading);
    const mainAccount = computed(() => state.accounts.mainAccount);
    const accounts = computed(() => state.accounts.data);
    const transferData = computed(() => accounts.value.find((e) => e.id == route.params.accId));

    const amountValidation = () => {
      if (!rules.amount[0].trigger[1]) {
        rules.amount[0].trigger[1] = 'change';
      }
      const validate = { min: 10, max: 99999999 };

      if (validate.min > dataSource.value.amount || validate.max < dataSource.value.amount) {
        errorAmount.value = true;
        return Promise.reject(i18n.t('messages.errorAmount', validate, 1) + ' USDT');
      } else {
        errorAmount.value = false;
        return Promise.resolve();
      }
    };

    const rules = reactive({
      amount: [
        {
          trigger: ['blur'],
          type: 'number',
          validator: amountValidation,
        },
      ],
    });

    const sendTransfer = () => {
      dataSource.value.account_from_id = transferData.value.id;
      dataSource.value.account_to_id = mainAccount.value.id;

      dispatch('sendTransfer', dataSource.value).then(() => {
        if (props.error) {
          emit('dataNotSended');
        } else {
          emit('dataSended');
        }
      });
    };

    return {
      i18n,
      dataSource,
      errorAmount,
      isLoading,
      mainAccount,
      transferData,
      rules,
      sendTransfer,
    };
  },
};
export default TransferModal;
</script>

<style></style>
